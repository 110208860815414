import * as bootstrap from 'bootstrap'


// or import specific components, like this:
// One:
// import Alert from 'bootstrap/js/dist/alert'
// Many:
// import { Tooltip, Toast, Popover } from 'bootstrap'
let changeInterval;
let bfNeon = document.getElementById("bf-logo-neon");
window.addEventListener("load", function() {
    changeInterval = setInterval(pulseLogo, 500);
})

function pulseLogo() {
    const toggle = bfNeon.dataset.toggle
    bfNeon.classList.add(toggle === "0" ? "on" : "off");
    bfNeon.classList.remove(toggle === "0" ? "off" : "on");
    bfNeon.dataset.toggle = toggle === "0" ? "1" : "0";
}
